<template>
    <el-dialog
      :visible="true"
      title="投票详情"
      width="920px"
      top="10vh"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="vote_detail">
            <h3>{{ detailValue.title }}</h3>
            <div class="vote_des">
                <span>{{ detailValue.voteCount }}人投票</span>
                <span>投票截止时间：{{ $moment(detailValue.voteEndDate).format('YYYY-MM-DD') }}</span>
            </div>
            <div class="v_option" v-for="(value, index) in Object.keys(dataSource)" :key="index">
                <div class="option_row">
                    <b>{{ value }}</b>
                    <span>{{ dataSource[value] }}票 {{ getProportion(dataSource[value], dataSource) }}</span>
                </div>
                <div class="option_bar">
                    <span :style="{ width: getProportion(dataSource[value], dataSource) }"> </span>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { toPercent } from '@/utils/utils'
export default {
    props: {
        detailValue: {
            type: Object,
            default: function() { return {} }
        },
    },
    data: function() {
        return {
            loading: false,
            dataSource: {},
        }
    },
    created() {
        try {
            this.dataSource = JSON.parse(this.detailValue.optionArray)
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
        // 关闭当前详情弹框
        beforeClose() {
            this.$emit('clearDetailValue')
        },
        // 投标占比
        getProportion(value, dataSource) {
            if (dataSource instanceof Object && Object.keys(dataSource).length > 0) {
                const numArr = Object.keys(dataSource).map((item) => {
                    return dataSource[item]
                })
                const reducer = (previousValue, currentValue) => previousValue + currentValue;
                const total = numArr.reduce(reducer)
                return toPercent(total > 0 ? value/total : 0, 0)
            }
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    color: #252525;
    font-weight: bold;
}
.vote_detail {
    h3 {
        font-size: 16px;
        font-weight: bold;
        color: #252525;
        margin-bottom: 8px;
    }
    .vote_des {
        margin-bottom: 32px;
        span {
            font-size: 12px;
            color: #8D8DA7;
            &:first-child {
                margin-right: 48px;
            }
        }
    }
}
.v_option {
    margin-bottom: 10px;
    .option_row {
        display        : flex;
        justify-content: space-between;
        margin-bottom  : 5px;
        align-items: center;
        b {
        font-weight: normal;
        flex: 1;
        color: #1A1A34;
        }
        span {
        color    : #8D8DA7;
        font-size: 12px;
        width: 22%;
        text-align: right;
        }
    }
    .option_bar {
        height    : 12px;
        background: #E2E2EA;
        span {
        height        : 12px;
        display       : inline-block;
        vertical-align: top;
        width         : 0;
        background    : #FEA644;
        }
    }
    }
</style>
