<template>
    <el-dialog
      :visible="true"
      title="报名详情"
      width="920px"
      top="10vh"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="signup_detail">
            <h3>{{ detailValue.title }}</h3>
            <div class="signup_des">
                <span>{{ detailValue.signUpCount }}人报名</span>
                <span>报名截止时间：{{ $moment(detailValue.endDate).format('YYYY-MM-DD') }}</span>
            </div>
            <div class="table_header">
                <h3>人员列表</h3>
                 <el-tooltip class="item" effect="dark" content="导出人员列表" placement="top">
                    <img src="../../../../../assets/images/download.svg" alt="" @click="exportPersonList">
                 </el-tooltip>
            </div>
            <div class="person_table">
                <el-table :data="dataSource" tooltip-effect="dark" style="width: 100%" v-loading="loading">
                    <el-table-column label="姓名" prop="userName"></el-table-column>
                    <el-table-column label="手机号" prop="phoneNumber"></el-table-column>
                    <el-table-column label="报名时间" prop="createTime"></el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[5, 10, 20, 40]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper, sizes"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import XLSX from "xlsx"
export default {
    props: {
        detailValue: {
            type: Object,
            default: function() { return {} }
        },
    },
    data: function() {
        return {
            loading: false, // table加载
            dataSource: [], // 实时访客列表
            pageSize: 10,
            pageNum: 1,
            total: 0,
        }
    },
    created() {
        this.getPersonList()
    },
    methods: {
        // 关闭当前详情弹框
        beforeClose() {
            this.$emit('clearDetailValue')
        },
        async getPersonList() {
            this.loading =  true
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                id: this.detailValue.id
            };
            try {
              const data = await this.API.dataStatistics.signUpStatisticsDetail(params);
              this.dataSource = data.data.list;
              this.total = data.data.total;
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        exportPersonList() {
            this.API.dataStatistics.signUpStatisticsDetail({
                pageNum: this.pageNum,
                pageSize: this.total,
                id: this.detailValue.id
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    const data = res.data.list
                    const newArr = [['序号', '姓名', '手机号', '报名时间'], ...data.map((item, index) => {
                        return [index + 1, item.userName, item.phoneNumber, item.createTime]
                    })]
                    const wb = XLSX.utils.book_new();
                    const ws = XLSX.utils.aoa_to_sheet(newArr);
                    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
                    XLSX.writeFile(wb, `${this.detailValue.title}报名人员列表.xlsx`);
                } else {
                    this.$message.error('下载导出失败！')
                }
            })
        },
         // 分页
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getPersonList();
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getPersonList();
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    color: #252525;
    font-weight: bold;
}
.signup_detail {
    h3 {
        font-size: 16px;
        font-weight: bold;
        color: #252525;
        margin-bottom: 8px;
    }
    .signup_des {
        margin-bottom: 32px;
        span {
            font-size: 12px;
            color: #8D8DA7;
            &:first-child {
                margin-right: 48px;
            }
        }
    }
    .table_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        h3 {
            margin-bottom: 0;
        }
        img {
            height: 24px;
            cursor: pointer;
        }
    }
}
.person_table {
    /deep/ .el-table thead th {
        background: #f2f2f8;
        color: #000000;
    }
    .pagination {
        text-align: center;
        margin: 20px auto 10px;
    }
}
</style>
