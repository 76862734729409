<template>
    <el-dialog
      :visible="true"
      title="数据详情"
      width="920px"
      top="10vh"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="video_detail">
            <h3>浏览趋势</h3>
            <div class="filter_item">
                <span v-for="item in filterArr" :key="item.value" :class="[currentFilter === item.value ? 'actice' : '']" @click="selectFilter(item.value)">
                    {{ item.label }}
                </span>
            </div>
            <FilterTime :onChange="selectTime" />
            <div class="line_echarts" v-loading="loading">
                <LineEcharts :dataSource="lineEchartsData" :currentFilter="currentFilter" />
            </div>
            <h3>实时访客</h3>
            <div class="visible_table">
                <el-table :data="dataSource" tooltip-effect="dark" style="width: 100%" v-loading="loading">
                    <el-table-column label="用户" prop="nickName">
                        <template slot-scope="scope">
                            <div class="userMsg">
                                <img :src="scope.row.headImg || '../../../../../assets/images/user.svg'" alt="">
                                <span>{{ scope.row.nickName }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="访问时间" prop="createTime"></el-table-column>
                    <el-table-column label="访问时长" prop="stayLength">
                        <template slot-scope="scope">
                            <span>{{ scope.row.stayLength }}秒</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否浏览分支" width="200px" align="right">
                        <template slot-scope="scope">
                            <span>{{ scope.row.branchVideoStatus === '1' ? '否' : '是' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[5, 10, 20, 40]"
                        :page-size="pageSize"
                        layout="total, prev, pager, next, jumper, sizes"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import FilterTime from "@/components/FilterTime";
import LineEcharts from './LineEcharts.vue';
export default {
    props: {
        detailValue: {
            type: Object,
            default: function() { return {} }
        }, // 当前视频参数
    },
    data: function() {
        return {
            filterArr: [
                {
                    label: '播放',
                    value: 'play',
                },{
                    label: '完播率',
                    value: 'completionRate',
                },{
                    label: '点赞数',
                    value: 'likedCount',
                },{
                    label: '分享数',
                    value: 'shareCount',
                },{
                    label: '元素点击',
                    value: 'elementClick',
                }
            ],
            currentFilter: 'play',
            lineEchartsData: [], // 趋势分析图
            loading: false, // table加载
            dataSource: [], // 实时访客列表
            startTime: '',
            endTime: '',
            pageSize: 10,
            pageNum: 1,
            total: 0
        }
    },
    components: {
        FilterTime,
        LineEcharts
    },
    created() {
        this.getVideoDetails()
    },
    methods: {
        // 通过类型筛选要查看的详情浏览趋势
        selectFilter(value) {
            this.currentFilter = value
        },
        // 通过时间筛选要查看的详情浏览趋势
        selectTime(value) {
            if (value && value.length > 1) {
                this.startTime = value[0];
                this.endTime = value[1];
            } else {
                this.startTime = "";
                this.endTime = "";
            }
            this.getVideoDetails();
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getVideoDetails();
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getVideoDetails();
        },
        async getVideoDetails() {
            this.loading = true
            const params = {
                id: this.detailValue.videoId,
                startTime: this.startTime,
                endTime: this.endTime,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            };
            try {
              const data = await this.API.dataStatistics.videoStatisticsDetail(params);
              this.lineEchartsData = data.data.iconDetail
              this.dataSource = data.data.userDetail.list
              this.total = data.data.userDetail.total
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        // 关闭当前详情弹框
        beforeClose() {
            this.$emit('clearDetailValue')
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    color: #252525;
    font-weight: bold;
}
.video_detail {
    h3 {
        font-size: 16px;
        font-weight: bold;
        color: #252525;
        margin-bottom: 8px;
    }
    .filter_item {
        margin: 24px 0;
        height: 32px;
        display: flex;
        align-items: center;
        span {
            padding: 8px 12px;
            background: #EDF0F7;
            border-radius: 16px;
            margin-right: 16px;
            color: #9093A0;
            cursor: pointer;
            &:hover {
                background: #FF7500;
                color: #fff;
            }
        }
        .actice {
            background: #FF7500;
            color: #fff;
        }
    }
    .line_echarts {
        border-top: 1px solid #D9DCE6;
        height: 270px;
        margin-top: 4px;
        padding: 18px 0;
    }
}
.visible_table {
    /deep/ .el-table thead th {
        background: #f2f2f8;
        color: #000000;
    }
    .userMsg {
        display: flex;
        align-items: center;
        > img {
            width: 36px;
            margin-right: 8px;
        }
    }
    .pagination {
        text-align: center;
        margin: 20px auto 10px;
    }
}
</style>
