<template>
    <div class="data_container">
        <header>
            <FilterTime :onChange="selectTime" />
            <div class="inputSearch">
                <el-input placeholder="请输入关键字进行搜索" v-model="keyword" @change="searchTitle">
                <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </header>
        <div class="content_table">
            <el-table :data="dataSource" tooltip-effect="dark" style="width: 100%" v-loading="loading">
                <el-table-column label="商品名称" prop="goodsName"></el-table-column>
                <el-table-column label="点击次数" prop="clickCount" sortable></el-table-column>
                <el-table-column label="浏览人数" prop="previewCount" sortable></el-table-column>
                <el-table-column label="操作"  width="100px" align="right">
                    <template slot-scope="scope">
                    <div class="operations">
                        <span @click="preview(scope.row)">详情</span>
                    </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper, sizes"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <GoodDetails v-if="detailValue" :detailValue="detailValue" @clearDetailValue="clearDetailValue"/>
    </div>
</template>
<script>
import FilterTime from "@/components/FilterTime";
import GoodDetails from './goodDetails.vue'
export default {
    props: {
        activeTab: String // 当前所在tab
    },
    data: function() {
        return {
            dataSource: [],
            keyword: "",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            loading: false,
            startTime: "",
            endTime: "",
            detailValue: undefined
        }
    },
    components: {
        FilterTime,
        GoodDetails
    },
    created() {
        this.getDataSource();
    },
    watch: {
        // 监听activeName的变化，当tab切换的时候刷新信息
        activeTab() {
            if (this.activeTab === "商品数据") {
                this.getDataSource();
            }
        }
    },
    methods: {
        preview(value) {
            this.detailValue = value
        },
        clearDetailValue() {
            this.detailValue = undefined
        },
        async getDataSource() {
            this.loading =  true
             const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                title: this.keyword || undefined,
                startTime: this.startTime || undefined,
                endTime: this.endTime || undefined,
            };
            try {
              const data = await this.API.dataStatistics.goodsStatistics(params);
              this.dataSource = data.data.list;
              this.total = data.data.total;
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getDataSource();
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getDataSource();
        },
        selectTime(value) {
            if (value && value.length > 1) {
                this.startTime = value[0];
                this.endTime = value[1];
            } else {
                this.startTime = "";
                this.endTime = "";
            }
             this.pageNum = 1;
            this.getDataSource();
        },
        searchTitle() {
            this.pageNum = 1;
            this.getDataSource();
        },
        // 更新
        // upDataSource() {
        //     Object.assign(this.$data, this.$options.data());
        //     this.getDataSource();
        // },
    },
}
</script>
<style lang="less" scoped>
.data_container {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d9dce6;
    .inputSearch {
      width: 26%;
      /deep/ .el-input-group__append {
        font-size: 18px;
        color: #fff;
        background-color: #ff9226;
        border-color: #ff9226;
      }
    }
  }
  .content_table {
        margin-top: 32px;
        /deep/ .el-table thead th {
            background: #f2f2f8;
            color: #000000;
        }
        .pagination {
            text-align: center;
            margin: 20px auto 10px;
        }
    }
}
</style>