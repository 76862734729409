<template>
    <div class="data_container">
        <header>
            <FilterTime :onChange="selectTime" />
            <div class="inputSearch">
                <el-input placeholder="请输入标题关键字进行搜索" v-model="keyword" @change="searchTitle">
                <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </header>
        <div class="content_table">
            <el-table :data="dataSource" tooltip-effect="dark" style="width: 100%" v-loading="loading" @sort-change="sortChange">
                <el-table-column label="视频标题" prop="title" width="200" fixed></el-table-column>
                <el-table-column label="发布时间" prop="publishTime" width="160"></el-table-column>
                <el-table-column label="视频作者" prop="userName" width="128" v-if="!userInfo.whiteStatus"></el-table-column>
                <el-table-column label="播放" prop="playCount" sortable="custom" width="116"></el-table-column>
                <el-table-column label="完播率" prop="completionRate" sortable="custom" width="114">
                     <template slot-scope="scope">
                        <span>{{ scope.row.completionRate ? toPercent(scope.row.completionRate) : `0%` }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="点赞" prop="likedCount" width="114"></el-table-column>
                <el-table-column label="分享" prop="shareCount" width="114"></el-table-column>
                <el-table-column label="名片点击" prop="businessCardCount" sortable="custom" width="114"></el-table-column>
                <el-table-column label="商品点击" prop="goodsCount" sortable="custom" width="114"></el-table-column>
                <el-table-column label="工具点击" prop="toolCount" sortable="custom" width="114"></el-table-column>
                <el-table-column label="行情点击" prop="quotationCount" sortable="custom" width="114"></el-table-column>
                <el-table-column label="开户点击" prop="openAccountCount" sortable="custom" width="114"></el-table-column>
                <el-table-column label="投票人数" prop="signUpNumber" sortable="custom" width="114"></el-table-column>
                <el-table-column label="报名人数" prop="voteNumber" sortable="custom" width="114"></el-table-column>
                <!-- <el-table-column label="操作" width="60px" align="right">
                    <template slot-scope="scope">
                    <div class="operations">
                        <span @click="preview(scope.row)">详情</span>
                    </div>
                    </template>
                </el-table-column> -->
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper, sizes"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <VideoDetails v-if="detailValue" :detailValue="detailValue" @clearDetailValue="clearDetailValue" />
    </div>
</template>
<script>
import VideoDetails from './videoDetails.vue';
import FilterTime from "@/components/FilterTime";
import { toPercent } from '@/utils/utils'
export default {
    props: {
        activeTab: String // 当前所在tab
    },
    data: function() {
        return {
            dataSource: [],
            keyword: "",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            loading: false,
            startTime: "",
            endTime: "",
            orderBy: "",
            detailValue: undefined,
            userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
        }
    },
    components: {
        FilterTime,
        VideoDetails
    },
    created() {
        this.getDataSource();
    },
    watch: {
        // 监听activeName的变化，当tab切换的时候刷新信息
        activeTab() {
            if (this.activeTab === "视频数据") {
                this.getDataSource();
            }
        }
    },
    methods: {
        sortChange(column) {
            this.orderBy = column?.order ? `${column.prop} ${column.order === 'descending' ? 'DESC' : 'ASC'}` : ''
            this.pageNum = 1;
            this.getDataSource()
        },
        // 小数转化百分比
        toPercent(value) {
            return toPercent(value, 0)
        },
        // 打开详情
        preview(value) {
            this.detailValue = value
        },
        // 关闭详情
        clearDetailValue() {
            this.detailValue = undefined
        },
        async getDataSource() {
            this.loading =  true
             const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                title: this.keyword || undefined,
                startTime: this.startTime || undefined,
                endTime: this.endTime || undefined,
                orderBy: this.orderBy || undefined,
            };
            try {
              const data = await this.API.dataStatistics.videoStatistics(params);
              this.dataSource = data.data.list.map((item) => {
                return {
                    ...item,
                    playCount: Number(item.playCount),
                    likedCount: Number(item.likedCount),
                    shareCount: Number(item.shareCount),
                    businessCardCount: Number(item.businessCardCount),
                    goodsCount: Number(item.goodsCount),
                    toolCount: Number(item.toolCount),
                    quotationCount: Number(item.quotationCount),
                    openAccountCount: Number(item.openAccountCount),
                    signUpNumber: Number(item.signUpNumber),
                    voteNumber: Number(item.voteNumber),
                }
              });
              this.total = data.data.total;
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getDataSource();
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getDataSource();
        },
        selectTime(value) {
            if (value && value.length > 1) {
                this.startTime = value[0];
                this.endTime = value[1];
            } else {
                this.startTime = "";
                this.endTime = "";
            }
            this.pageNum = 1;
            this.getDataSource();
        },
        searchTitle() {
            this.pageNum = 1;
            this.getDataSource();
        },
        // 更新
        // upDataSource() {
        //     Object.assign(this.$data, this.$options.data());
        //     this.getDataSource();
        // },
    },
}
</script>
<style lang="less" scoped>
.data_container {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d9dce6;
    .inputSearch {
      width: 26%;
      /deep/ .el-input-group__append {
        font-size: 18px;
        color: #fff;
        background-color: #ff9226;
        border-color: #ff9226;
      }
    }
  }
  .content_table {
        margin-top: 32px;
        /deep/ .el-table thead th {
            background: #f2f2f8;
            color: #000000;
        }
        .operations {
            span {
                cursor: pointer;
                &:hover {
                    color: #FF9226;
                }
            }
        }
        .pagination {
            text-align: center;
            margin: 20px auto 10px;
        }
    }
}
</style>