<template>
    <el-dialog
      :visible="true"
      title="名片详情"
      width="920px"
      top="10vh"
      :closeOnClickModal="false"
      :before-close="beforeClose"
      append-to-body
      center
    >
        <div class="card_detail">
            <h3>浏览趋势</h3>
            <FilterTime :onChange="selectTime" />
            <div class="line_echarts" v-loading="lineEchartsLoading">
                <LineEcharts :dataSource="lineEchartsData" />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import FilterTime from "@/components/FilterTime";
import LineEcharts from './LineEcharts.vue'
export default {
    props: {
        detailValue: {
            type: Object,
            default: function() { return {} }
        },
    },
    data: function() {
        return {
            lineEchartsLoading: false, // echart加载
            lineEchartsData: [], // 趋势分析图
            startTime: "",
            endTime: "",
        }
    },
    components: {
        FilterTime,
        LineEcharts
    },
    created() {
        this.getCardDetails()
    },
    methods: {
         // 通过时间筛选要查看的详情浏览趋势
        selectTime(value) {
            if (value && value.length > 1) {
                this.startTime = value[0];
                this.endTime = value[1];
            } else {
                this.startTime = "";
                this.endTime = "";
            }
            this.getCardDetails();
        },
        // 关闭当前详情弹框
        beforeClose() {
            this.$emit('clearDetailValue')
        },
        // 获取名片详情
        async getCardDetails() {
            this.lineEchartsLoading =  true
            const params = {
                id: this.detailValue.id,
                startTime: this.startTime,
                endTime: this.endTime
            };
            try {
              const data = await this.API.dataStatistics.businessCardStatisticsDetail(params);
              this.lineEchartsData = data.data.iconDetail
              this.lineEchartsLoading = false
            } catch (error) {
              this.lineEchartsLoading = false
            }
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    color: #252525;
    font-weight: bold;
}
.card_detail {
    h3 {
        font-size: 16px;
        font-weight: bold;
        color: #252525;
        margin-bottom: 8px;
    }
    .line_echarts {
        border-top: 1px solid #D9DCE6;
        height: 320px;
        margin-top: 4px;
        padding: 18px 0;
    }
}
</style>
