<template>
  <el-form v-model="filterForm">
    <!-- <el-radio-group v-model="filterForm.filterTimeQuantum" @change="selectTimeQuantum">
      <el-radio label="browse">浏览量</el-radio>
      <el-radio label="visitor">访客数</el-radio>
    </el-radio-group> -->
    <el-radio-group v-model="filterForm.filterTimeQuantum" @change="selectTimeQuantum">
      <el-radio label="all_days" v-if="useAll">全部</el-radio>
      <el-radio label="seven_days">7天</el-radio>
      <el-radio label="fifteen_days">15天</el-radio>
      <el-radio label="thirty_days">30天</el-radio>
    </el-radio-group>
    <el-form-item label="筛选时间" label-width="70px">
      <el-date-picker
        v-model="filterForm.timeScope"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="selectTimeScope"
        :unlink-panels="true"
      >
      </el-date-picker>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    onChange: {
      type: Function,
      default: function() {}
    }, // 选择时间后的回调
    useTime: {
      type: Boolean,
      default: true
    }, // 控制change出去的date格式
    useAll: {
      type: Boolean,
      default: true
    } // 是否有全部选项
  },
  data: function() {
    return {
      oldTimeQuanTum: "all_days",
      filterForm: {
        filterTimeQuantum: this.useAll ? "all_days" : "seven_days",
        timeScope: null
      }, // 时间筛选
      pickerMinDate: "", //获取开始选择时间
      pickerMaxDate: "", //获取结束选择时间
      pickerOptions: {
        //时间范围选择控制
        onPick: ({ maxDate, minDate }) => {
          if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
          if (maxDate) {
            this.pickerMinDate = "";
            this.pickerMaxDate = maxDate.getTime();
          }
        },
        disabledDate: time => {
          const day30 = (30 - 1) * 24 * 3600 * 1000;
          if (this.pickerMinDate !== "") {
            let maxTime = this.pickerMinDate + day30;
            if (maxTime > new Date()) {
              maxTime = new Date();
            }
            return time.getTime() > maxTime || time.getTime() > Date.now();
          }
          if (this.pickerMaxDate !== "" && !this.pickerMinDate) {
            let minTime = this.pickerMaxDate - day30;
            return time.getTime() < minTime || time.getTime() > Date.now();
          }
          return time.getTime() > Date.now();
        }
      }
    };
  },
  // created() {
  //   if (!this.useAll) {
  //     this.filterForm = {
  //       filterTimeQuantum: "seven_days",
  //       timeScope: null
  //     };
  //   }
  // },
  computed: {
    // 通过时间筛选，获取时间段
    timeScopeArr: function() {
      if (this.filterForm.filterTimeQuantum.length > 0) {
        switch (this.filterForm.filterTimeQuantum) {
          case "seven_days":
            return this.useTime
              ? [
                  `${this.$moment()
                    .subtract(6, "days")
                    .format("YYYY-MM-DD")} 00:00:00`,
                  `${this.$moment().format("YYYY-MM-DD")} 23:59:59`
                ]
              : [
                  this.$moment()
                    .subtract(6, "days")
                    .format("YYYY-MM-DD"),
                  this.$moment().format("YYYY-MM-DD")
                ];
            break;
          case "fifteen_days":
            return this.useTime
              ? [
                  `${this.$moment()
                    .subtract(14, "days")
                    .format("YYYY-MM-DD")} 00:00:00`,
                  `${this.$moment().format("YYYY-MM-DD")} 23:59:59`
                ]
              : [
                  this.$moment()
                    .subtract(14, "days")
                    .format("YYYY-MM-DD"),
                  this.$moment().format("YYYY-MM-DD")
                ];
            break;
          case "thirty_days":
            return this.useTime
              ? [
                  `${this.$moment()
                    .subtract(29, "days")
                    .format("YYYY-MM-DD")} 00:00:00`,
                  `${this.$moment().format("YYYY-MM-DD")} 23:59:59`
                ]
              : [
                  this.$moment()
                    .subtract(29, "days")
                    .format("YYYY-MM-DD"),
                  this.$moment().format("YYYY-MM-DD")
                ];
            break;
          case "all_days":
            return [];
            break;
          default:
            "";
            return this.useTime
              ? [`${this.$moment().format("YYYY-MM-DD")} 00:00:00`, `${this.$moment().format("YYYY-MM-DD")} 23:59:59`]
              : [this.$moment().format("YYYY-MM-DD"), this.$moment().format("YYYY-MM-DD")];
            break;
        }
      }
      if (this.filterForm.timeScope.length > 0) {
        return this.filterForm.timeScope.map((item, index) => {
          if (index === 0) {
            return this.useTime
              ? `${this.$moment(item).format("YYYY-MM-DD")} 00:00:00`
              : this.$moment(item).format("YYYY-MM-DD");
          } else {
            return this.useTime
              ? `${this.$moment(item).format("YYYY-MM-DD")} 23:59:59`
              : this.$moment(item).format("YYYY-MM-DD");
          }
        });
      }
    }
  },
  watch: {
    timeScopeArr: function() {
      // console.log(this.timeScopeArr)
      this.onChange(this.timeScopeArr);
    }
  },
  methods: {
    // 选择7、15、30天 趋势分析
    selectTimeQuantum(value) {
      if (value && value.length > 0) {
        this.filterForm.timeScope = null;
      }
    },
    // 选择时间段 趋势分析
    selectTimeScope(value) {
      if (value && Array.isArray(value) && value.length > 0) {
        this.oldTimeQuanTum = this.filterForm.filterTimeQuantum;
        this.filterForm.filterTimeQuantum = "";
      } else {
        this.filterForm.filterTimeQuantum = this.oldTimeQuanTum;
      }
    }
  }
};
</script>
<style scoped lang="less">
.el-form {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
    margin-left: 24px;
  }
}
</style>
