<template>
    <div class="data_statistics">
        <el-tabs v-model="activeTab">
            <el-tab-pane label="视频数据" name="视频数据" lazy>
                <VideoData :activeTab="activeTab" />
            </el-tab-pane>
            <el-tab-pane label="名片数据" name="名片数据" lazy>
                <CardData :activeTab="activeTab" />
            </el-tab-pane>
            <el-tab-pane label="商品数据" name="商品数据" lazy>
                <GoodData :activeTab="activeTab" />
            </el-tab-pane>
            <el-tab-pane label="投票数据" name="投票数据" lazy>
                <VoteData :activeTab="activeTab" />
            </el-tab-pane>
            <el-tab-pane label="报名数据" name="报名数据" lazy>
                <SignupData :activeTab="activeTab" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import VideoData from './videoData/index.vue'
import CardData from './cardData/index.vue'
import GoodData from './goodData/index.vue'
import VoteData from './voteData/index.vue'
import SignupData from './signupData/index.vue'
export default {
    data: function() {
        return {
            activeTab: '视频数据',
        }
    },
    components: {
        VideoData,
        CardData,
        GoodData,
        VoteData,
        SignupData
    },
}
</script>
<style lang="less" scoped>
 .data_statistics {
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    /deep/ .el-tabs {
        margin-top: 8px;
        padding: 0 24px;
        .el-tabs__header {
            margin-bottom: 10px;
        }
        .el-tabs__active-bar {
            width: 18px !important;
            margin-left: 18px;
            height: 4px;
        }
        .el-tabs__item {
            color: #8d8da7;
            font-weight: bold;
            height: 36px;
        }
        .el-tabs__item.is-active {
            color: #252525;
            font-weight: bold;
        }
        .el-tabs__nav-wrap::after {
            height: 0;
        }
    }
 }
</style>