<template>
  <div id="lineEchart" :style="{height:height,width:width}"></div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    dataSource: {
      type: Array,
      default: function() {
        return []
      }
    },
    currentFilter: {
      type: String,
      default: 'play'
    },
  },
  data: function() {
    return {
      chart: null,
    }
  },
  methods: {
    createOptions(dataSource, currentFilter) {
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          left: 24,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      }
      // 图标数据
      options.xAxis.data = dataSource.map(item => item.date)
      if (currentFilter === 'play') {
        options.legend.data = ['播放次数', '播放人数']
        options.series = [
          {
            name: '播放次数',
            type: 'line',
            data: []
          },
          {
            name: '播放人数',
            type: 'line',
            data: []
          },
        ]
      }
      if (currentFilter === 'completionRate') {
        options.legend.data = ['完播率']
        options.series = [
          {
            name: '完播率',
            type: 'line',
            data: []
          },
        ]
      }
      if (currentFilter === 'likedCount') {
        options.legend.data = ['点赞数']
        options.series = [
          {
            name: '点赞数',
            type: 'line',
            data: []
          },
        ]
      }
      if (currentFilter === 'shareCount') {
        options.legend.data = ['分享数']
        options.series = [
          {
            name: '分享数',
            type: 'line',
            data: []
          },
        ]
      }
      if (currentFilter === 'elementClick') {
        options.legend.data = ['名片点击','商品点击','工具点击','行情点击','开户点击']
        options.series = [
          {
            name: '名片点击',
            type: 'line',
            data: []
          },
          {
            name: '商品点击',
            type: 'line',
            data: []
          },
          {
            name: '工具点击',
            type: 'line',
            data: []
          },
          {
            name: '行情点击',
            type: 'line',
            data: []
          },
          {
            name: '开户点击',
            type: 'line',
            data: []
          },
        ]
      }
      dataSource.forEach((item) => {
        options.series.forEach(val => {
          if (val.name === '播放次数') {
            val.data.push(item.playTotalCount)
          }
          if (val.name === '播放人数') {
            val.data.push(item.browseTotalCount)
          }
          if (val.name === '完播率') {
            val.data.push(item.completionRate || 0)
          }
          if (val.name === '点赞数') {
            val.data.push(item.likedCount)
          }
          if (val.name === '分享数') {
            val.data.push(item.shareCount)
          }
          if (val.name === '名片点击') {
            val.data.push(item.businessCardCount)
          }
          if (val.name === '商品点击') {
            val.data.push(item.goodsCount)
          }
          if (val.name === '工具点击') {
            val.data.push(item.toolCount)
          }
          if (val.name === '行情点击') {
            val.data.push(item.quotationCount)
          }
          if (val.name === '开户点击') {
            val.data.push(item.openAccountCount || 0)
          }
        })
      })
      return options
    }
  },
  watch: {
    dataSource: function() {
      this.chart.clear()
      this.chart.setOption(this.createOptions(this.dataSource, this.currentFilter))
    },
    currentFilter: function() {
      this.chart.clear()
      const option = this.createOptions(this.dataSource, this.currentFilter)
      this.chart.setOption({...option})
    }
  },
  mounted() {
    this.chart = this.$echarts.init(document.getElementById('lineEchart'));
    this.chart.setOption(this.createOptions(this.dataSource))
  },
}
</script>
