<template>
  <div id="lineEchart" :style="{height:height,width:width}"></div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    dataSource: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  data: function() {
    return {
      chart: null,
    }
  },
  methods: {
    createOptions(dataSource) {
      const options = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['点击次数', '拨打次数', '预览人数'],
          left: 24,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '点击次数',
            type: 'line',
            data: []
          },
          {
            name: '拨打次数',
            type: 'line',
            data: []
          },
          {
            name: '预览人数',
            type: 'line',
            data: []
          },
        ]
      }
      // 图标数据
      options.xAxis.data = dataSource.map(item => item.date)
      dataSource.forEach((item) => {
        options.series.forEach(val => {
          if (val.name === '点击次数') {
            val.data.push(item.clickCount)
          }
          if (val.name === '拨打次数') {
             val.data.push(item.phoneClickCount)
          }
          if (val.name === '预览人数') {
            val.data.push(item.previewCount)
          }
        })
      })
      return options
    }
  },
  watch: {
    dataSource: function() {
      this.chart.setOption(this.createOptions(this.dataSource))
    }
  },
  mounted() {
    this.chart = this.$echarts.init(document.getElementById('lineEchart'));
    this.chart.setOption(this.createOptions(this.dataSource))
  },
}
</script>
